import React from 'react';
import { Fragment } from 'react';
import { classNames, changePercentage, nanoToMin } from './utils';
import ChangeIndicator from './components/ChangeIndicator';
import { periods } from './ReportsPage'

import {
  ChevronDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from '@heroicons/react/20/solid';

import { Menu, Tab, Transition } from '@headlessui/react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

function subtractDays(date, days) {
  date.setDate(date.getDate() - days);
  return date;
}

function listDatesInPeriod(period, includeToday = false) {
  var dates = [];

  let from = subtractDays(new Date(), period)
  let until = subtractDays(new Date(), 1);

  if (includeToday) {
    // Shift window by one day
    addDays(from, 1);
    addDays(until, 1);
  }

  let current = from;
  while (current <= until) {
    dates.push(new Date(current));
    current = addDays(current, 1);
  }

  return dates;
}

function fillBlankDates(period, data, includeToday = false) {
  let dataMap = new Map(
    data.map((record) => {
      let key = new Date(record.date).toDateString();

      return [key, record];
    })
  );
  
  let enrichedData = listDatesInPeriod(period, includeToday).map((date) => {
    let record = dataMap.get(date.toDateString());

    if (record) {
      return {
        date: new Date(date),
        totalRuntime: record.totalRuntimeNano,
        totalBuilds: record.totalBuilds,
        totalDelayed: record.totalDelayedNano,
      };
    }

    return {
      date: new Date(date),
      totalRuntime: 0,
      totalBuilds: 0,
      totalDelayed: 0,
    };
  });

  return enrichedData;
}

function slice(period, data) {
  return {
    current: data.slice(period, period * 2),
    preceding: data.slice(0, period),
  };
}

function totalBuilds(data) {
  return data.reduce((acc, val) => acc + val.totalBuilds, 0);
}

function totalRuntime(data) {
  return data.reduce((acc, val) => acc + nanoToMin(val.totalRuntime), 0);
}

function buildChartData(data) {
  return data.current.map((current, index) => {
    let preceding = data.preceding[index];

    return {
      date: current.date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      precedingDate: preceding.date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      current: current.totalBuilds,
      preceding: preceding.totalBuilds,
      changePercentage: changePercentage(
        current.totalBuilds,
        preceding.totalBuilds
      ),
    };
  });
}

function runtimeChartData(data) {
  return data.current.map((current, index) => {
    const preceding = data.preceding[index];
    const currentRuntime = nanoToMin(current.totalRuntime);
    const precedingRuntime = nanoToMin(preceding.totalRuntime);

    return {
      date: current.date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      precedingDate: preceding.date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      current: Math.round(currentRuntime),
      preceding: Math.round(precedingRuntime),
      changePercentage: changePercentage(currentRuntime, precedingRuntime),
    };
  });
}

const StatsTab = ({ name, item }) => (
  <Tab
    className={({ selected }) =>
      classNames(
        'border-t-4 px-4 py-5 sm:px-6 sm:pt-6 text-left focus:outline-none',
        selected ? 'border-indigo-500' : 'border-transparent'
      )
    }
  >
    <p className="mb-2 truncate text-sm font-medium text-gray-500">{name}</p>
    <p className="mb-2 text-2xl font-semibold text-gray-900">{item.stat}</p>
    <p
      className={classNames(
        item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
        'flex items-baseline text-sm font-semibold'
      )}
    >
      {item.changeType === 'increase' ? (
        <ArrowUpIcon
          className="h-5 w-5 flex-shrink-0 self-center text-green-500"
          aria-hidden="true"
        />
      ) : (
        <ArrowDownIcon
          className="h-5 w-5 flex-shrink-0 self-center text-red-500"
          aria-hidden="true"
        />
      )}

      <span className="sr-only">
        {' '}
        {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by{' '}
      </span>
      {item.change}
    </p>
  </Tab>
);

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const renderLegendText = (value, entry) => {
  const { color } = entry;

  return (
    <span className="font-medium" style={{ color }}>
      {capitalize(value)}
    </span>
  );
};

const CustomTooltip = ({ title, active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="bg-white shadow sm:rounded-lg ring-1 ring-black ring-opacity-5">
        <div className="px-4 py-4">
          <p className="truncate text-sm font-medium text-gray-500">
            {`${data.date} vs ${data.precedingDate}`}
          </p>

          <p className="mt-2 text-base font-semibold text-gray-900">
            {data.current}{' '}
            <span className="font-medium text-gray-500">{title}</span>
          </p>
          <div className="mt-1">
            <ChangeIndicator change={data.changePercentage} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const ComparisonChart = ({ data, tooltipTitle = '' }) => (
  <ResponsiveContainer width="100%" height="100%">
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis interval="preserveStartEnd" />
      <Tooltip content={<CustomTooltip title={tooltipTitle} />} />
      <Legend formatter={renderLegendText} align="left" />
      <Line
        type="monotone"
        dot={false}
        dataKey="current"
        stroke="#8884d8"
        strokeWidth={3}
        legendType="plainline"
        isAnimationActive={false}
      />
      <Line
        type="monotone"
        dot={false}
        dataKey="preceding"
        stroke="#419a62"
        strokeWidth={2}
        strokeDasharray="4 4"
        legendType="plainline"
        isAnimationActive={false}
      />
    </LineChart>
  </ResponsiveContainer>
);

class ReportCard extends React.Component {
  constructor(props) {
    super(props);

    let orgs = this.props.api.getOwners() || [];
    let selectedOrg = localStorage.getItem('selectedOrg') || orgs[0];

    let selectedPeriod = localStorage.getItem('selectedPeriod') || 28;

    this.state = {
      orgs,
      selectedOrg,
      selectedPeriod: selectedPeriod,
      refreshing: false,
      data: {},
      period: 'Last 28 days',
    };
  }

  componentDidMount() {
    this.updateStats(this.state.selectedOrg, this.state.selectedPeriod);
  }

  async updateStats(owner, period) {
    this.setState({ refreshing: true });

    let report = await this.props.api.getBuildReport(owner, period * 2);

    const today = 1;

    if (report.stats) {
      let includeToday = period == today;
      
      let data = slice(period, fillBlankDates(period * 2, report.stats, includeToday));

      let currentBuilds = totalBuilds(data.current);
      let precedingBuilds = totalBuilds(data.preceding);

      let currentRuntime = totalRuntime(data.current);
      let precedingRuntime = totalRuntime(data.preceding);

      let buildChange = changePercentage(currentBuilds, precedingBuilds);
      let runtimeChange = changePercentage(currentRuntime, precedingRuntime);

      let stats = {
        builds: {
          stat: currentBuilds,
          change: `${Math.abs(buildChange)}%`,
          changeType: buildChange < 0 ? 'decrease' : 'increase',
        },
        runtime: {
          stat: Math.round(currentRuntime),
          change: `${Math.abs(runtimeChange)}%`,
          changeType: runtimeChange < 0 ? 'decrease' : 'increase',
        },
        buildChartData: buildChartData(data),
        runtimeChartData: runtimeChartData(data),
      };

      this.setState({ data: stats });
    } else {
      this.setState({ data: {} });
    }

    this.setState({ refreshing: false });
  }

  selectPeriod(period) {
      localStorage.setItem(
        'selectedPeriod',
        period
      );
      this.setState({
        selectedPeriod: period,
      });
      this.updateStats(
        this.state.selectedOrg,
        period
      );
  }

  selectOrg(org) {
    localStorage.setItem(
      'selectedOrg',
      org
    );
    this.setState({
      selectedOrg: org
    });
                                
    this.updateStats(
      org,
      this.state.selectedPeriod
    );
  }

  render() {
    let { data, orgs, refreshing } = this.state;

    return (
      <div className='relative'>
      <div className="divide-y divide-gray-300 overflow-hidden rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5">
        <div className="py-3 bg-gray-50 h-16">
          <div className="flex justify-end absolute right-0 left-0 px-4 sm:px-6">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                {this.state.selectedOrg
                  ? this.state.selectedOrg
                  : 'No org selected'}
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {orgs.map((org, index) => {
                      return (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <a
                              onClick={() => this.selectOrg(org)}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {org}
                            </a>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="ml-3 flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                { this.state.selectedPeriod == 1 ? 'Today' : `Last ${this.state.selectedPeriod} days`}
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {periods.map((period) => {
                      return (
                        <Menu.Item key={period.id}>
                          {({ active }) => (
                            <a
                              onClick={() => this.selectPeriod(period.value)}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {period.text}
                            </a>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div>
          {data && data.builds && data.runtime ? (
            <Tab.Group>
              <Tab.List className="flex">
                <StatsTab
                  key="builds"
                  name="Builds"
                  item={data.builds}
                ></StatsTab>
                <StatsTab
                  key="minutes"
                  name="Minutes"
                  item={data.runtime}
                ></StatsTab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="h-80" key="builds">
                  <ComparisonChart
                    tooltipTitle="Builds"
                    data={data.buildChartData}
                  ></ComparisonChart>
                </Tab.Panel>
                <Tab.Panel className="h-80" key="minutes">
                  <ComparisonChart
                    data={data.runtimeChartData}
                    tooltipTitle="Minutes"
                    unit="min"
                  ></ComparisonChart>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          ) : (
            <div className="flex justify-center py-8">
              {refreshing ? (
                <svg className="animate-spin h-8 w-8 mr-3" viewBox="0 0 24 24">
                  <path
                    className="opacity-75 fill-indigo-500"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <p>No data available</p>
              )}
            </div>
          )}
        </div>
      </div>
      </div>
    );
  }
}

export default ReportCard;
