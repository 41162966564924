import React from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

function formatDuration(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60); // Floor seconds to avoid decimals

  const parts = [];
  if (h > 0) parts.push(`${h}h`);
  if (m > 0) parts.push(`${m}m`);
  if (s > 0 || parts.length === 0) parts.push(`${s}s`);

  return parts.join('');
}

class JobQueueTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { jobs: props.jobs };

    TimeAgo.setDefaultLocale(en.locale);
    TimeAgo.addLocale(en);
    this.state.timeAgo = new TimeAgo("en-US");
  }

  render() {
    let jobs = this.props.jobs;
    // console.log("Rendering: props", this.props.jobs, "state", this.state.jobs)

    let formatJobTime = (job) => {
      let fmt = "";
      let started = new Date(job.startedAt);
      let now = new Date();
      // was this less than 24 seconds ago?
      const seconds30 = 30 * 1000;
      if (now - started < seconds30 && job.status == "queued") {
        fmt = "Just now";
      } else {
        fmt =
          this.state.timeAgo.format(new Date(job.startedAt), "mini") + " ago";
      }
      return fmt;
    };

    let getETA = (job) => {
      let eta = "";
      if (job.status !== "queued" && job.averageRuntime > 0) {
        if (job.startedAt != null) {
          const runningTime = Date.now() - new Date(job.startedAt).getTime();
          // averageDuration in milliseconds
          const avgDuration =  job.averageRuntime / 1e6;
          let etaV = avgDuration - runningTime;
          if (etaV < 0) {
            etaV = -etaV;
            eta = `+${formatDuration(etaV / 1000)}`;
          } else {
            eta = `${formatDuration(etaV / 1000)}`;
          }
        }
      }

      return eta;
    };

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Build queue</h1>
            <p className="mt-2 text-sm text-gray-700">
              Jobs queued or in-progress for your organisation
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={this.props.onRefresh}
              type="button"
              disabled={this.props.refreshing}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              {this.props.refreshing ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  viewBox="0 0 24 24"
                  visibility={this.props.refreshing ? "show" : "hidden"}
                >
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ""
              )}
              {this.props.refreshing ? "Refreshing" : "Refresh"}
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Repository
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Job
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell lg:table-cell"
                      >
                        Actor
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell lg:table-cell"
                      >
                        Agent
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Created
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        ETA
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {jobs && jobs.length ? (
                      jobs.map((job) => (
                        <tr key={job.job_id}>
                          <td className="whitespace-nowrap px-2 py-4 py-2 pr-3 sm:pl-6 pl-2 text-sm font-medium text-gray-900">
                            <a
                              className="hover:underline hover:text-indigo-600 hover:text-indigo-500"
                              href={
                                "https://github.com/" +
                                job.owner +
                                "/" +
                                job.repo
                              }
                            >
                              {job.owner}/{job.repo}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            <a
                              className="hover:underline hover:text-indigo-600 hover:text-indigo-500"
                              href={
                                "https://github.com/" +
                                job.owner +
                                "/" +
                                job.repo +
                                "/runs/" +
                                job.job_id
                              }
                            >
                              {job.workflow_name}: {job.job_name}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 hidden sm:table-cell md:table-cell lg:table-cell">
                            <a
                              className="hover:underline hover:text-indigo-600 hover:text-indigo-500"
                              href={"https://github.com/" + job.actor}
                            >
                              {job.actor}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {job.status == "in_progress"
                              ? "Running"
                              : job.status == "queued"
                              ? "Queued"
                              : job.status}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 hidden md:table-cell lg:table-cell">
                            {job.agent_name ? job.agent_name : "-"}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {job.startedAt ? formatJobTime(job) : "-"}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {getETA(job)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key="empty-row">
                        <td
                          className="whitespace-nowrap px-2 py-4 py-2 pr-3 sm:pl-6 pl-2 text-sm text-gray-900"
                          colSpan={6}
                        >
                          No jobs in the build queue.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JobQueueTable;
