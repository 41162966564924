export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function changePercentage(current, preceding) {
  if (preceding == 0) {
    return current == 0 ? 0 : 100
  }
  return Math.round((100 * (current - preceding)) / preceding);
}

export function nanoToMin(nano) {
  const minuteNanos = 60000000000;

  return nano / minuteNanos;
}

// minsToDuration prints in the format: 10m20s, and if
// there are no seconds, just: 10m  
export function minsToDuration(mins) {
  const minutes = Math.floor(mins);
  const seconds = Math.round((mins - minutes) * 60);

  if (seconds > 0) {
    return `${minutes}m${seconds}s`;
  }

  return `${minutes}m`;
}