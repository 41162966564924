import Cookies from 'universal-cookie';
const jose = require('jose');

class Api {
    constructor() {
        this.url = process.env.REACT_APP_API_URL || ""
        this.defaultFetchOptions = {}

        if (process.env.NODE_ENV === 'development') {
            this.defaultFetchOptions.credentials = 'include'
        }
    }

    getOwners() {
        let cookie = new Cookies();
        let token = cookie.get("actuated_dashboard");

        const claims = jose.decodeJwt(token)
        let orgs = []
        if(claims.orgs && claims.orgs.length) {
            orgs = claims.orgs.split(",")
        }

        return orgs
    }

    async getRunners () {
        const response = await fetch(`${this.url}/api/runners`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        let runners= await response.json();
        if(runners) {
            runners.sort((a,b) => a.vms > b.vms ? -1 : 1 );
        }

        return runners;
    }

    async getJobQueue () {
        let cookie = new Cookies();
        let token = cookie.get("actuated_dashboard");

        const claims = jose.decodeJwt(token)

        // console.log(claims,claims.orgs)
        let qs= '?owners=' + claims.orgs
        const response = await fetch(`${this.url}/api/job-queue`+qs, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        let jobs= await response.json();
        jobs.sort((a,b) => {
            
            let dateDiff = new Date(a.startedAt) < new Date(b.startedAt) ? -1 : 1
            if(a.status == 'in_progress' && b.status != 'in_progress') {
                return -1
            } else if(a.status != 'in_progress' && b.status == 'in_progress') {
                return 1
            }

            return dateDiff;
        });
                
        return jobs;
    }

    async getRepoStats (period, includeToday = false) {
        let cookie = new Cookies();
        let token = cookie.get("actuated_dashboard");

        const claims = jose.decodeJwt(token)

        let qs= `?owners=${claims.orgs}&period=${period}&include_today=${includeToday}`
        const response = await fetch(`${this.url}/api/repo-stats`+qs, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        if (response.ok) {
            return await response.json();
        }

        return [];
    }

    async getJobIncreases (owner, startDate) {

        const startDateShort = new Date(startDate).toISOString().split('T')[0]
        let qs= `?owner=${owner}&startDate=${startDateShort}`
        const response = await fetch(`${this.url}/api/job-increases`+qs, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        if (response.ok) {
            return await response.json();
        }

        return [];
    }

    // stat.owner,
    // stat.repo,
    // stat.workflow_name,
    // stat.job_name,
    // stat.current_year,
    // stat.current_week
    async getDailyMaximumsByJob (owner, repo, workflowName, jobName, year, week) {
        let qs = new URLSearchParams({
            owner: owner,
            repo: repo,
            workflowName: workflowName,
            jobName: jobName,
            year: year,
            week: week
        }).toString()
        
        const response = await fetch(`${this.url}/api/job-daily-maximums?${qs}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        if (response.ok) {
            return await response.json();
        }

        return [];
    }

    async getUserStats (period, includeToday = false) {
        let cookie = new Cookies();
        let token = cookie.get("actuated_dashboard");

        const claims = jose.decodeJwt(token)

        let qs= `?owners=${claims.orgs}&period=${period}&include_today=${includeToday}`
        const response = await fetch(`${this.url}/api/user-stats`+qs, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        if (response.ok) {
            return await response.json();
        }

        return [];
    }

    async getDailyStats () {
        let cookie = new Cookies();
        let token = cookie.get("actuated_dashboard");

        const claims = jose.decodeJwt(token)

        // console.log(claims,claims.orgs)
        let qs= '?owners=' + claims.orgs
        const response = await fetch(`${this.url}/api/daily-stats`+qs, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        let stats= await response.json();
        stats.sort((a,b) => a.totalJobs > b.totalJobs ? -1 : 1 );
        return stats;
    }

    async getBuildReport (owner, period) {
        let qs= `?owner=${owner}&period=${period}`
        const response = await fetch(`${this.url}/api/build-report`+qs, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        if (response.ok) {
            let report = await response.json();
            return report;
        }

        return {}
    }

    async getDebugSessions() {
        const response = await fetch(`${this.url}/api/debug-sessions`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        if (response.ok) {
            let sessions = await response.json();
            return sessions;
        }

        return []
    }

    async getEvents () {
        let cookie = new Cookies();
        let token = cookie.get("actuated_dashboard");

        const claims = jose.decodeJwt(token)

        let qs= `?owners=${claims.orgs}`
        const response = await fetch(`${this.url}/api/events`+qs, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
            ...this.defaultFetchOptions
        });

        if (response.ok) {
            return await response.json();
        }

        return [];
    }
}

export default Api;